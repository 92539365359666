
<template>
  <div>
    <HeaderLayout></HeaderLayout>

    <!--Container 3 -->

    <div style="clear: both; display: block; height: 0px;"></div>

    <!--Container - Popular Resorts -->
    <div class="w-full mx-auto pt-0 bg-white">
      <div
        class="flex w-full  bg-gradient-to-r from-gray-100 via-blue-100 to-green-100 text-left"
      >
        <h1
          class="text-2xl font-bold my-8 max-w-7xl md:mx-auto md:pl-4 mx-auto px-2 sm:px-6"
        >
          Affiliates & Partners
        </h1>
      </div>
      <div class="max-w-7xl mx-auto px-4 mt-20 mb-20 sm:px-6">
        <div
          class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4"
        >
          <div
            class="flex flex-col items-center justify-center bg-white p-4 shadow rounded-lg"
          >
            <div
              class="inline-flex shadow-lg border border-gray-200 rounded-full overflow-hidden h-40 w-40"
            >
              <img src="/CyryxLogo.jpg" alt="" class="h-full w-full" />
            </div>

            <h2 class="mt-4 font-bold text-xl">Cyryx College</h2>
            <h6 class="mt-2 text-sm font-medium">Partner</h6>
          </div>

          <div
            class="flex flex-col items-center justify-center bg-white p-4 shadow rounded-lg"
          >
            <div
              class="inline-flex shadow-lg border border-gray-200 rounded-full overflow-hidden h-40 w-40"
            >
              <img src="/ConfoodLogo.jpg" alt="" class="h-full w-full" />
            </div>

            <h2 class="mt-4 font-bold text-xl">Confood Pvt Ltd</h2>
            <h6 class="mt-2 text-sm font-medium">Affiliate</h6>
          </div>

          <div
            class="flex flex-col items-center justify-center bg-white p-4 shadow rounded-lg"
          >
            <div
              class="inline-flex shadow-lg border border-gray-200 rounded-full overflow-hidden h-40 w-40"
            >
              <img src="/CityTradeLogo.jpg" alt="" class="h-full w-full" />
            </div>

            <h2 class="mt-4 font-bold text-xl">City Trade Pvt Ltd</h2>
            <h6 class="mt-2 text-sm font-medium">Partner</h6>
          </div>

          <div
            class="flex flex-col items-center justify-center bg-white p-4 shadow rounded-lg"
          >
            <div
              class="inline-flex shadow-lg border border-gray-200 rounded-full overflow-hidden h-40 w-40"
            >
              <img src="/DhiCoolLogo.jpg" alt="" class="h-full w-full" />
            </div>

            <h2 class="mt-4 font-bold text-xl">DhiCool</h2>
            <h6 class="mt-2 text-sm font-medium">Maldives</h6>
          </div>

          <div
            class="flex flex-col items-center justify-center bg-white p-4 shadow rounded-lg"
          >
            <div
              class="inline-flex shadow-lg border border-gray-200 rounded-full overflow-hidden h-40 w-40"
            >
              <img
                src="https://stark.webdesignmaldives.com/public/wdmlogo.png"
                alt=""
                class="h-full w-full"
              />
            </div>

            <h2 class="mt-4 font-bold text-xl">WDM</h2>
            <h6 class="mt-2 text-sm font-medium">Technology Partner</h6>
          </div>
        </div>
      </div>
    </div>
    <FooterLayout> </FooterLayout>
  </div>
</template>

<script>
import HeaderLayout from "./PageLayouts/Header.Layout.vue";
import FooterLayout from "./PageLayouts/Footer.Layout.vue";

export default {
  name: "AffiliatePage",
  data() {
    return {};
  },
  mounted() {},
  methods: {},

  computed: {},
  components: {
    HeaderLayout,
    FooterLayout
  }
};
</script>

<style scoped></style>
